import '../App.css';
import './Music.css'
import React, { useState, useEffect, useRef } from 'react';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useSwipeable } from 'react-swipeable';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper, Typography, Autocomplete, TextField, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack'
import BeatLoader from "react-spinners/BeatLoader";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

function Music() {

  const [songs, setSongs] = useState<any[]>();
  const [persisted, setPersisted] = useState<any[]>();
  const [filtered, setFiltered] = useState<any[]>();
  const [pages, setPages] = useState<string[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>();
  const [editing, setEditing] = useState<boolean>(false);

  const handlers = useSwipeable({
    onSwipedLeft: (e) => swipeLeft(),
    onSwipedRight: (e) => swipeRight()
  });

  useEffect(() => {
    if (songs) {
      window.sessionStorage.setItem("my-piano-music", JSON.stringify(songs));
      setPersisted(songs);
      setFiltered(songs);
    }
  }, [songs]);

  useEffect(() => {
    let storage = window.sessionStorage.getItem("my-piano-music");
    let parsedStorage = storage ? JSON.parse(storage) : undefined;
    if (!songs && (persisted || parsedStorage))
      setSongs(persisted ?? parsedStorage);
    else {
      fetch("/songs")
        .then((res) => res.json())
        .then(async res => {
          setSongs(res.CommonPrefixes);
          //get metadata after loading song list
          const rawResponse = await fetch('/details', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ folders: res.CommonPrefixes.map((x: { Prefix: string; }) => x.Prefix) })
          });
          const content = await rawResponse.json();
          setSongs(songs => songs?.map((y, z) => ({ Metadata: content[z].Metadata, ...y })))
        })
        .catch(error => {
          // handle the error
        });

    }
  }, []);

  const getImages = (name: string) => {
    fetch(`/songs/${name}`)
      .then((res) => res.json())
      .then(res => {
        setPages(res.Contents.map((item: any) => { return item.Key; }));
        if (res.Contents[1].Key.indexOf('pdf') == -1)
          setTotalPages(res.Contents.length - 1);
      })
      .catch(error => {
        // handle the error
      });
  }

  const swipeLeft = () => {
    if (totalPages && pageNumber + 1 <= totalPages)
      setPageNumber(pageNumber => (pageNumber + 1))
    else {
      setPageNumber(1);
      setPages([])
    }
  }

  const swipeRight = () => {
    if (pageNumber - 1 >= 0)
      setPageNumber(pageNumber => (Math.max(1, pageNumber - 1)))
    else {
      setPageNumber(1);
      setPages([])
    }
  }

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setTotalPages(numPages);
  }

  const tags = [
    { title: "Singable" },
    { title: "Ellen's", },
    { title: "Jessie's" },
    { title: "Hymn" },
    { title: "Disney" }
  ]
  const onFilter = (event: object, value:any) => {
    if (value.length && value[0].title.toLowerCase() == "singable") {
      setFiltered(filtered => (filtered ?? []).filter(x => x.Metadata.singable == "true"))
    }
    else if (value.length == 0 && songs)
      setFiltered(songs);
  }
  const actions = [
    { icon: <CloudUploadIcon />, name: 'Add' },
    { icon: <EditIcon />, name: 'Edit' },
  ];
  return (
    <>
      {pages.length == 0 ? <div className="App">
        <Autocomplete
          multiple
          limitTags={2}
          id="multiple-limit-tags"
          options={tags}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField {...params} placeholder="Filter music" />
          )}
          sx={{ margin: '15px 0px 15px 0px' }}
          onChange={(event: object, value:any) => onFilter(event, value)}
        />
        <TableContainer component={Paper} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell><b>Song Name</b></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(filtered ?? songs) && (filtered ?? songs ?? []).map((song) => (
                <TableRow
                  key={song.Prefix}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" onClick={() => getImages(song.Prefix)}>
                    {song.Prefix.slice(0, -1)}
                  </TableCell>
                  <TableCell style={{ textAlign: "right", color: "rgb(141 139 139)" }}>
                    {song.Metadata && <small>{song.Metadata.artist}</small>}
                  </TableCell>
                  {editing && <div>test</div>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div> : ((pages[1].indexOf('.pdf') > -1) ?
        <div {...handlers}>
          <Document loading={<BeatLoader
            color="rgb(61, 128, 114)"
            loading={true}
            size={20}
            className="loading-spinner"
            aria-label="Loading Spinner"
            data-testid="loader"
          />} onLoadSuccess={onDocumentLoadSuccess} className="pdf-song" file={`https://my-piano-music.s3.us-west-2.amazonaws.com/${pages[1]}`} renderMode='svg'>
            <Page
              loading={<BeatLoader
                color="rgb(61, 128, 114)"
                loading={true}
                size={20}
                className="loading-spinner"
                aria-label="Loading Spinner"
                data-testid="loader"
              />}
              pageNumber={pageNumber}
              className="pdf_page"
              renderTextLayer={false}
              width={window.innerWidth > 400 ? window.innerWidth - 100 : window.innerWidth}
            />
          </Document></div> : <img {...handlers} className="image-song-page" src={`https://my-piano-music.s3.us-west-2.amazonaws.com/${pages[pageNumber]}`} />)}
          <SpeedDial
        className="desktop-display-only"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<MoreVertIcon />} ariaLabel={''}        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => setEditing(editing => !editing)}
            />
          ))}
        </SpeedDial>
    </>
  );
}

export default Music;
