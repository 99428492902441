import './App.css';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from "react-router-dom";
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import NewSong from './components/NewSong';
import Music from './components/Music'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import "./fonts/Metropolis-ExtraLight.otf";

declare module '@mui/material/styles' {
  interface TypographyVariants {
    poster: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    poster: true;
    h3: false;
  }
}

function App() {
  const [user, setUser] = useState<{ email: string, familyName: string, givenName: string, googleId: string, imageUrl: string, name: string }>();
  const [mainpage, setMainPage] = useState<boolean>(true);


  const theme = createTheme({
    palette: {
      primary: { main: "#ad343e" },
      secondary: { main: "#f2af29" },
    },
    typography: {
      poster: {
        fontFamily: 'Carnival'
      },
      h3: undefined,
    },
  });

  const logout = () => {
    setUser(undefined);
  }
  const responseGoogle = (response: any) => {
    if (response.profileObj) {
      setUser(response.profileObj);
    }
  }
 

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <Typography component="div" sx={{ flexGrow: 1, my: 2, fontFamily: "Carnival", fontSize: "1em", fontWeight: "bold" }} color="secondary">
                Piano Music
              </Typography>
              {user ? <div className="user">
                {!mainpage || window.location.pathname == "/newsong" ? <Link to="/" className="add-song go-back" onClick={() => setMainPage(true)}>&larr;</Link> : <Link to="/newsong" className="add-song" onClick={() => setMainPage(false)}>+</Link>}
                <img src={user.imageUrl} referrerPolicy="no-referrer" /><p>{user.givenName}</p>
                <GoogleLogout
                  clientId="466688304097-3chnt59js2793lps34611jis7q3vtkhv.apps.googleusercontent.com"
                  buttonText="Logout"
                  onLogoutSuccess={logout} /></div> : null}
              {/*<div className="login-box"><GoogleLogin
              clientId="466688304097-3chnt59js2793lps34611jis7q3vtkhv.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={responseGoogle}
              isSignedIn={true}
              onFailure={responseGoogle}
              className="google-login"
            />
        </div>*/}
            </Toolbar>
          </AppBar>
        </Box>
      <Routes>
        <Route path="/" element={<Music />} />
        <Route path="/newsong" element={<NewSong />} />
      </Routes>
        
      </ThemeProvider>
      </Router>
  );
}

export default App;
