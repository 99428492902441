import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {TextField, Button, FormControl, FormGroup, Checkbox, FormControlLabel, FormLabel, FormHelperText} from '@mui/material';
import './NewSong.css';

function NewSong() {
  const input = useRef<any>(null);
  const [songName, setSongName] = useState<string>("");
  const [checked, setChecked] = useState<{ [key:string] : boolean }>({ "Disney":false, "Lorie Line": false, "60s": false});

  const Input = styled('input')({
    display: 'none',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(checked => ({...checked, [event.target.name]: event.target.checked}))
  };

  const submit = (event: any) => {
    fetch(`/upload/${songName}`)
      .then((res) => res.json())
      .then(res => {
        debugger
      })
      .catch(error => {
        // handle the error
      });
      return false;
  }

  return (
    <div className="new-song">
      <div>
        <form action={`/upload/${songName}`} target="dummyframe" method="post" encType="multipart/form-data" >
        <TextField type="text" name="add-song" id="standard-basic" label="Song Name" variant="standard" required value={songName} onChange={(e) => setSongName(e.currentTarget.value)} />
          <TextField id="standard-basic" label="Artist" variant="standard" />
         <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormLabel component="legend">Add tags</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox checked={checked["Display"]} onChange={handleChange} name="Disney" />
            }
            label="Disney"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checked["Lorie LIne"]} onChange={handleChange} name="Lorie Line" />
            }
            label="Lorie Line"
          />
          <FormControlLabel
            control={
              <Checkbox checked={checked["60s"]} onChange={handleChange} name="60s" />
            }
            label="60s"
          />
        </FormGroup>
        {/*<FormHelperText>Be careful</FormHelperText>*/}
      </FormControl>
         <label htmlFor="add-song">
          <Input accept="image/*" id="add-song" name="add-song" type="file" multiple ref={input} />
          <Button variant="outlined" component="span">Choose Files</Button>
        </label>
          <Button type="submit" className="submit-button" variant="contained" color="primary">Upload</Button>
        </form>
      </div>
      <iframe name="dummyframe" id="dummyframe" style={{"display": "none"}}></iframe>

    </div>
  );
}

export default NewSong;
